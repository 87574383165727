<template>
  <div class="gallery">

    <light-box :images="images" :index="index" @close="index = null" />

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Gallery
        </h1>
      </div>
    </div>

    <div class="content-container" style="padding-top: 40px">

      <div class="columns is-multiline images">
        <div class="column is-4" v-for="(image, i) in images" :key="i" @click="index = i">
          <div class="image-container">
            <div class="image is-5by3"><img :src="image" /></div>
          </div>
        </div>
      </div>

      <!-- <div class="pad shadow">
        <b-carousel
          :autoplay="false"
          :indicator="false"
          :arrow="true"
          icon-pack="far"
          icon-next="chevron-right"
          :arrow-hover="false">
        <b-carousel-item v-for="(image, i) in images" :key="i">
          <a class="image is-5by3" @click="index = i"><img :src="image"></a>
        </b-carousel-item>
        </b-carousel>
      </div> -->

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      index: null,
      images: [
        require('@/assets/img/gallery/0.jpg'),
        require('@/assets/img/gallery/1.jpg'),
        require('@/assets/img/gallery/2.jpg'),
        require('@/assets/img/gallery/3.jpg'),
        require('@/assets/img/gallery/4.jpg'),
        require('@/assets/img/gallery/5.jpg'),
        require('@/assets/img/gallery/6.jpg'),
        require('@/assets/img/gallery/7.jpg'),
        require('@/assets/img/gallery/8.jpg'),
        require('@/assets/img/gallery/9.jpg'),
        require('@/assets/img/gallery/10.jpg'),
        require('@/assets/img/gallery/11.jpg'),
        require('@/assets/img/gallery/12.jpg'),
        require('@/assets/img/gallery/13.jpg'),
        require('@/assets/img/gallery/14.jpg'),
        require('@/assets/img/gallery/15.png'),
        require('@/assets/img/gallery/16.png'),
        require('@/assets/img/gallery/17.png'),
        require('@/assets/img/gallery/18.jpg'),
        require('@/assets/img/gallery/19.jpg'),
        require('@/assets/img/gallery/20.jpg'),
        require('@/assets/img/gallery/21.jpg'),
        require('@/assets/img/gallery/22.jpg')
      ]
    }
  }
}
</script>
